import React from "react";
import Banner from "../Banner/Banner";

const Preorder = () => {
  return (
    <div>
      <Banner url={"preorder1.png"} />
    </div>
  );
};

export default Preorder;
