import React from "react";
import Banner from "../Banner/Banner";

const Service = () => {
  return (
    <div>
      <Banner url={"about.png"} title={"Services"} />
    </div>
  );
};

export default Service;
