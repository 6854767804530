import React from "react";
import SearchVerification from "./SearchVerification";

export const VerifyAuction = () => {
  return (
    <div>
      <SearchVerification />
    </div>
  );
};

export default VerifyAuction;
